import PFP from "../assets/images/placeholder.jpg";
import { useEffect, useState } from "react";
import { DropDown } from "./Dropdown";

export const TopNav = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(function onFirstMount() {
    window.onclick = (e) => {
      let elementId = e?.target?.id;
      if (elementId !== "dropdownMenu") {
        setShowDropDown(false);
      }
    };
  }, []);

  return (
    <div className="w-full mt-4 pl-16 pr-5 flex justify-between">
      <span className="text-xl text-gray-500 font-medium">Bot</span>

      <div>
        {showDropDown && <DropDown />}
        <button onClick={() => setShowDropDown(!showDropDown)}>
          <img
            src={PFP}
            alt="pfp"
            id="dropdownMenu"
            className="w-8 h-8 rounded-full"
          />
        </button>
      </div>
    </div>
  );
};
