import { ReactComponent as ChatSVG } from "../assets/svgs/chat.svg";
import { ReactComponent as MicSVG } from "../assets/svgs/mic.svg";
import { ReactComponent as SendSVG } from "../assets/svgs/send.svg";
import { ReactComponent as LikeSVG } from "../assets/svgs/like.svg";
import { ReactComponent as LikeFilledSVG } from "../assets/svgs/like-filled.svg";
import { ReactComponent as UnLikeFilledSVG } from "../assets/svgs/unlike-filled.svg";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ReactComponent as UnlikeSVG } from "../assets/svgs/unlike.svg";
import PFP from "../assets/images/placeholder.jpg";
import { useState, useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import axios from "axios"
import { NotificationManager } from 'react-notifications';
import { ReactComponent as ResetSVG } from "../assets/svgs/reset.svg";
import { fetchEventSource } from "@microsoft/fetch-event-source";

import { useNavigate } from "react-router-dom";
export const Chat = () => {
  const [text, setText] = useState();
  const [chatsArray, setChatsArray] = useState([{content:"showModels",isSystemMessage:true}]);
  console.log("🚀 ~ Chat ~ chatsArray:", chatsArray)
  const navigate = useNavigate()
  const divRef = useRef(null);
  const [dataToRender, setDataToRender] = useState([]);
  const [modelsAvailable, setModelsAvailable] = useState([]);
  const [selectedModel,setSelectedModel] = useState();
  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  });
  useEffect(()=>{
    axios.post(`https:///aibotprimebackend.online/models/list?pageNo=1&limit=1000`, {

    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }).then(response =>{
      setModelsAvailable(response.data.models)
    }).catch(console.log)
 
  },[])
  useEffect(()=>{
    let chatArray = [...chatsArray,null];
    if(selectedModel>=0){
      chatArray[chatArray.length-1] = { content:`Model Selected for current Session is : ${modelsAvailable[selectedModel]}`,questionId: null, isSystemMessage:true}
      
      setChatsArray(chatArray)
  
    }
  },[selectedModel])
  useEffect(
     ()=>{
      if(dataToRender.length!=0){
        if(dataToRender == "showModels"){
          axios.post(`https://aibotprimebackend.online/models/list?pageNo=1&limit=1000`, {

        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }).then(response =>{
          setModelsAvailable(response.data.models)
    
        }).catch(console.log)
        }else{
          if(chatsArray.length%2==1){
            let chatArray = [...chatsArray,{ content:dataToRender.map(item => item.message).join(" "),questionId: dataToRender[0].questionId,isSystemMessage: dataToRender[0]?.isSystemMessage ?? false, isAnswer:dataToRender[0]?.isAnswer??false}];
            setChatsArray(chatArray)
  
          }else{
            let chatArray = [...chatsArray];
            chatArray[chatArray.length-1] = { content:dataToRender.map(item => item.message).join(" "),questionId: dataToRender[0].questionId,isSystemMessage: dataToRender[0]?.isSystemMessage ?? false, isAnswer:dataToRender[0]?.isAnswer??false}
            setChatsArray(chatArray)
    
          }
        }
    
  
      }
    }
    ,[dataToRender])
  const fetchData = async (question) => {
    try {
      await fetchEventSource(`https://aibotprimebackend.online/ask`, {
        method: "POST",
        cache: "no-cache",
        //  keepalive: true,
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/event-stream",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          // "question": "Can you tell me what the 'Article V.- Principle of specialized justice' is about?"
          "question": question,
          index_name:  modelsAvailable[selectedModel]
        
        }),
        onopen:(response) => {
          return {
        }
        },
        onmessage(event) {
          const fad = JSON.parse(event.data)
          setDataToRender((dataToRender) => [...dataToRender, {message:fad.data.info,questionId:fad.data.questionId,isSystemMessage:false,isAnswer:true}]);
        },
        onclose() {
          const timer = setTimeout(() => {
            setDataToRender([]);
            clearTimeout(timer)
          }, 2000);

          console.log("Connection closed by the server");
        },
        onerror(err) {
          // return null

          throw err
          console.log("There was an error from server", err);
        },
      });

    } catch (err) {
      console.log(err)
    }
  };
  const clickHandler = async () => {
    if (text == "showModels"){
      axios.post(`https://aibotprimebackend.online/models/list?pageNo=1&limit=1000`, {

    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }).then(response =>{
      setModelsAvailable(response.data.models)
    }).catch(console.log)
    }
    if (text && text !== chatsArray[chatsArray?.length - 1] ) {
      let chatArray = [...chatsArray, { content: text,isSystemMessage:true }];
     
      setChatsArray(chatArray);
      if(text != "showModels"){
        await fetchData(text);

      }
      setText("");
    }
  };
  const giveFeedback = async (index, isPositive) => {
    const messages = chatsArray
    if (isPositive == 1) {
      messages[index].isPositive = true
      const response = await axios.post(`https://aibotprimebackend.online/feedback?question=${messages[index].questionId}`, {
        isCorrect: "True"
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        }
      })
    } else if (isPositive == 0) {
      messages[index].isPositive = false
      const response = await axios.post(`https://aibotprimebackend.online/feedback?question=${messages[index].questionId}`, {
        isCorrect: "True"
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        }
      })
    } else if (isPositive == -1) {
      const response = await axios.post(`https://aibotprimebackend.online/feedback?question=${messages[index].questionId}`, {
        isCorrect: "null"
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        }
      })
      delete messages[index].isPositive
    }
    setChatsArray([...messages])

  }

  return (

    <div className="flex flex-col justify-between Right bg-slate-100 rounded-3xl w-full h-full mr-14 p-4 pr-7 -m-1 relative">

      <Scrollbars
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              backgroundColor: "#9ca3af",
              borderRadius: "4px",
            }}
          />
        )}
      >
        <div className="flex flex-col gap-4 pb-32">
          {chatsArray?.map((message, i) => (
            message.content =="showModels"?
            
            <div
            key={i}
            className="bg-white rounded-2xl py-4 px-3 flex flex-col"
          >
            <div className="flex gap-2">
           
                <div className="animate-pulse">
                  <div className="animate-spin">
                    <ChatSVG />
                  </div>
                </div>
              

              <span className="px-2 text-base leading-8">Please select 1 of the possible options we have:</span>
           
             
            </div>
            <ul>
              {
                modelsAvailable.map((item,index) => <li> - <span onClick={(event =>{
                  setSelectedModel(index)
                })} style={{cursor:"pointer",color:"blue",textDecoration:"underline"}} className="px-2 text-base leading-8">{item}</span><br/></li>)
              }
              </ul>
          </div>
            :
            <div
            key={i}
            className="bg-white rounded-2xl py-4 px-3 flex flex-col"
          >
            <div className="flex gap-2">
              {i % 2 === 0  || message.isSystemMessage == false ? (
                <img src={PFP} alt="pfp" className="w-8 h-8 rounded-full" />
              ) : (
                <div className="animate-pulse">
                  <div className="animate-spin">
                    <ChatSVG />
                  </div>
                </div>
              )}

              <span className="px-2 text-base leading-8">{message.content}</span>
            </div>

            {((i % 2 === 1  && message?.isSystemMessage == false) || message.isAnswer == true) && (
              <div className="flex gap-2 px-12 mt-6"

              >
                <div className="border border-gray-300 hover:bg-blue-100 rounded-lg p-1.5">
                  {
                    message.isPositive != undefined && message.isPositive ?
                      <LikeFilledSVG width={"38px"} height={"38px"} onClick={async (event) => {

                        await giveFeedback(i, -1)
                      }} /> :
                      <LikeSVG width={"38px"} height={"38px"} onClick={async (event) => {

                        await giveFeedback(i, 1)
                      }} />
                  }

                </div>

                <div  className="border border-gray-300 hover:bg-blue-100 rounded-lg p-1.5">
                  {
                    message.isPositive != undefined && message.isPositive == false ?
                      <UnLikeFilledSVG width={"38px"} height={"38px"}  onClick={async (event) => {
                        await giveFeedback(i, -1)
                      }} /> :
                      <UnlikeSVG width={"38px"} height={"38px"} onClick={async (event) => {

                        await giveFeedback(i, 0)
                      }} />
                  }
                </div>
              </div>
            )}
          </div>
      
          ))}
        </div>
        <div ref={divRef} />
      </Scrollbars>
      )

      <div className="flex flex-col bg-slate-100 pt-5 text-center w-full absolute bottom-3 left-0">
        <div className="flex items-center gap-1 mx-8">
          <div className="w-full h-14 rounded-full bg-white border border-gray-500 flex items-center justify-between pl-8 pr-2">
            <input
              type="text"
              className="text-base font-medium text-gray-500 w-full outline-none"
              placeholder="Enter a prompt here"
              onChange={(e) => setText(e?.target?.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  clickHandler();
                }
              }}
              value={text}
            />

            <div className="rounded-full hover:bg-gray-50 p-4">
              <MicSVG />
            </div>
          </div>

          <button
            className="rounded-full hover:bg-blue-100 p-4"
            onClick={clickHandler}
          >
            <SendSVG />

          </button>
          <button
            className="rounded-full hover:bg-blue-100 p-4"
            onClick={(event) => {
              const options = {
                title: 'Reset',
                message: 'Are you sure you want to reset the whole chat',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      setChatsArray([])
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => { }
                  }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,
                keyCodeForClose: [8, 32],
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypress: () => { },
                onKeypressEscape: () => { },
                overlayClassName: "overlay-custom-class-name"
              };
              confirmAlert(options);
            }}
          >
            <ResetSVG />

          </button>
        </div>

        <span className="text-xs text-gray-700 mt-4">
          Enter your question, and get answers instantly
        </span>
      </div>
    </div>
  );
};
