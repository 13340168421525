import { ReactComponent as LogoutSVG } from "../assets/svgs/logout.svg";
import { ReactComponent as AccountSVG } from "../assets/svgs/account.svg";
import { Navigate, useLocation, useNavigate } from "react-router-dom"

export const DropDown = () => {
  const navigate = useNavigate();

  const optionsClass =
    "text-gray-700 group flex items-center gap-4 px-4 py-2 text-sm cursor-pointer transition duration-200 hover:font-medium hover:bg-gray-200/50";

  return (
    <div className="relative inline-block text-left">
      <div
        className="absolute -top-2 -right-2 z-10 mt-2 w-52 rounded-md bg-slate-100 shadow-xl ring-2 ring-gray-200/50"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        {/* <div className="py-1" role="none">
          <div
            className={optionsClass}
            role="menuitem"
            tabIndex={-1}
            id="menu-item-0"
          >
            <AccountSVG />
            Update Account
          </div>
        </div> */}

        <div className="py-1" role="none">
          <div
            className={optionsClass}
            role="menuitem"
            tabIndex={-1}
            id="menu-item-0"
            onClick={(event) => {
              localStorage.setItem("token", null)
              navigate("/login")
            }}
          >
            <LogoutSVG />
            Log Out
          </div>
        </div>
      </div>
    </div>
  );
};
