import { ReactComponent as ResetSVG } from "../assets/svgs/reset.svg";
import { ReactComponent as ActivitySVG } from "../assets/svgs/activity.svg";
import { ReactComponent as FAQSVG } from "../assets/svgs/faq.svg";
import { ReactComponent as SupportSVG } from "../assets/svgs/support.svg";

const NavTab = ({ element, title, href }) => {
  return (
    href != undefined ? <a href={href}>
      <div className="flex py-4 pl-6 gap-4 items-center hover:bg-gray-100 rounded-r-full cursor-pointer">
        <div className="w-6 h-6">{element}</div>
        <span className="text-lg text-gray-600">{title}</span>
      </div>

    </a> : <div className="flex py-4 pl-6 gap-4 items-center hover:bg-gray-100 rounded-r-full cursor-pointer">
      <div className="w-6 h-6">{element}</div>
      <span className="text-lg text-gray-600">{title}</span>
    </div>

  );
};

export const SideNav = () => {
  return (
    <div className="w-80 flex flex-col gap-10">
      <div>
        {/* <NavTab element={<ResetSVG />} title="Reset chat" /> */}

        {/* <NavTab element={<ActivitySVG />} title="Activity" />

        <NavTab element={<FAQSVG />} title="FAQ" /> */}

        <NavTab element={<a >
          <SupportSVG />
        </a>} title="Help & support" href="mailto:taaha827@gmail.com" />
      </div>
    </div>
  );
};
