import { Chat } from "./Chat";
import { SideNav } from "./SideNav";
import { TopNav } from "./TopNav";

export const Home = () => {
  return (
    <div className="flex flex-col h-screen gap-5">
      <TopNav />

      <div className="flex w-full h-screen gap-10 mb-6 overflow-hidden">
        <SideNav />

        <Chat />
      </div>
    </div>
  );
};

export default Home;
