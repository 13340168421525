import { Login } from "../components/Login";
import { useState } from "react";
import { Signup } from "../components/Signup";
import { useEffect } from 'react'
import { fetchEventSource } from "@microsoft/fetch-event-source";
const LoginPage = () => {

  const [accountExist, setAccountExist] = useState(true);
  return (
    <>
      {accountExist ? (
        <Login setOpen={() => setAccountExist(false)} />
      ) : (
        <Signup setOpen={() => setAccountExist(true)} />
      )}
    </>
  );
};

export default LoginPage;
