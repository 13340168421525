import { useState } from "react";
import axios from "axios"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import { NotificationManager } from 'react-notifications';

export const Login = ({ setOpen }) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {forgotPassword ? (
        <div className="flex h-full lg:h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Recover your account
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full text-center sm:max-w-[480px] flex flex-col gap-6">
            <span className="text-xs font-medium text-gray-600">
              We will send you an account recovery code on the email you enter
              below
            </span>

            <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
              <form className="space-y-6" action="/">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm text-left font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:outline-none sm:text-sm sm:leading-6 px-4"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send Code
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-full lg:h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
              <form className="space-y-6" onSubmit={async (event) => {
                event.preventDefault()
                try {
                  const response = await axios.post('https://aibotprimebackend.online/login', {
                    email: event.target.elements.email.value,
                    password: event.target.elements.password.value
                  })
                  localStorage.setItem("token", response.data.access_token)
                  navigate("/")
                } catch (err) {
                  NotificationManager.error(err?.message ?? "Something Went Wrong");
                  console.log("🚀 ~ file: Login.jsx:75 ~ <formclass~ err:", err)

                }


              }} >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:outline-none sm:text-sm sm:leading-6 px-4"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:outline-none sm:text-sm sm:leading-6 px-4"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {/* <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      Remember me
                    </label> */}
                  </div>

                  <div className="text-sm leading-6">
                    {/* <button
                      className="font-semibold text-indigo-600 hover:text-blue-600 cursor-pointer"
                      onClick={() => setForgotPassword(true)}
                    >
                      Forgot password?
                    </button> */}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>

              <p className="mt-10 text-center text-sm text-gray-500">
                Not a member?{" "}
                <button
                  className="font-semibold leading-6 text-indigo-600 hover:text-blue-600 underline cursor-pointer"
                  onClick={setOpen}
                >
                  Create Account
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
