import "./App.css";
import {
  // useQuery,
  // useMutation,
  // useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import LoginPage from "./pages/LoginPage";
import Verification from "./pages/Verification";
import ProtectedRoute from "./pages/ProtectedRoute";
// Create a client
const queryClient = new QueryClient();
const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/verification",
    element: <Verification />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <ProtectedRoute>
      <HomePage />
    </ProtectedRoute>,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationContainer/>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
